import React, { useContext, useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import { AutomaticPopupContext } from 'context/automatic-popup/provider'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { Hero } from 'mx/how-it-works/hero'
import { PhaseOne } from 'mx/how-it-works/phase-1'
import { PhaseTwo } from 'mx/how-it-works/phase-2'
import { PhaseThree } from 'mx/how-it-works/phase-3'
import { PhaseFour } from 'mx/how-it-works/phase-4'
import Layout from 'mx/layout'

const HowItWorksPage = () => {
  const { dispatch } = useContext(AutomaticPopupContext)

  useEffect(() => {
    dispatch({
      type: 'show_modal',
      value: false,
    })
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'How It Works',
    })
  }, [])

  return (
    <Layout>
      <AutomaticPopup />
      <Hero />
      <PhaseOne phase={1} />
      <PhaseTwo phase={2} />
      <PhaseThree phase={3} />
      <PhaseFour phase={4} />
    </Layout>
  )
}

export default HowItWorksPage

export const Head = () => (
  <SEO
    title="How It Works"
    description="Learn all about our process and how to design your new modern kitchen with FORM."
    path="/how-it-works"
  />
)
